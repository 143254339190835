import React from 'react'
import propTypes from 'prop-types'
import PageWrapper from '../page-wrapper'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import ProvenResponsiveContainer from '../proven-responsive-container'

const themeProven = createProvenTheme()

/**
 * ProvenPage is a page wrapper that handles all SEO properties,
 * and provides a content container with the outer horizontal margins defined in our style guide.
 *
 * It must be used for all new pages that are based on our custom Proven Theme.
 *
 * @param children
 * @param withoutProvenResponsiveContainer when it is true, children is not wrapped with a ProvenResponsiveContainer
 * @param any SEO props that PageWrapper component accepts
 * @returns {JSX.Element}
 */

const ProvenPage = ({ children, background, withoutProvenResponsiveContainer, ...props }) => {
  return (
    <ThemeProvider theme={themeProven}>
      <PageWrapper {...props} background={background ? background : themeProven.palette.gray.white}>
        {withoutProvenResponsiveContainer ? (
          children
        ) : (
          <ProvenResponsiveContainer minHeight={`${window.innerHeight}px`}>
            {children}
          </ProvenResponsiveContainer>
        )}
      </PageWrapper>
    </ThemeProvider>
  )
}

ProvenPage.defaultProps = {
  withoutProvenResponsiveContainer: false
}

ProvenPage.propTypes = {
  children: propTypes.node.isRequired,
  withoutProvenResponsiveContainer: propTypes.bool
}

export default ProvenPage
