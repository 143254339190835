import React from 'react'
import { FormControlLabel, Radio } from '@mui/material'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'

const ProvenToggle = ({ label, selected, value, sx }) => (
  <FormControlLabel
    sx={[
      {
        backgroundColor: 'common.white',
        borderRadius: 1,
        p: [2, 2, 3],
        border: 1,
        borderColor: 'gray.middleGray',
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        '&:hover': {
          cursor: 'pointer'
        },
        '.MuiFormControlLabel-label': {
          width: 1
        },
        ...sx
      },
      selected && {
        backgroundColor: 'gray.lightBlue',
        borderColor: 'primary.main'
      }
    ]}
    control={<Radio disableRipple sx={{ p: 0.5, mr: 1, pt: '6px', [onDesktop]: { pt: '7px' } }} />}
    label={label}
    checked={selected}
    value={value}
  />
)

ProvenToggle.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  selected: propTypes.bool,
  value: propTypes.number,
  sx: propTypes.object
}

export default ProvenToggle
